import classNames from "classnames";
import * as React from "react";
import { Menu, MenuItem } from "react-aria-menubutton";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { toast } from "react-toastify";

import style from "./template-kebab-menu.scss";
import AlertDialog from "components/alert-dialog/AlertDialog";
import DeleteConfirmationDialog from "components/confirmation/DeleteConfirmationDialog";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import Delete from "components/icons/Delete";
import Download from "components/icons/Download";
import { SuccessIcon } from "components/icons/SuccessIcon";
import { TitleIcon } from "components/icons/TitleIcon";
import kebabStyle from "components/kebab-menu/kebab-menu.scss";
import layout from "components/layout/layout.scss";
import MenuItemButton from "components/menu-item-button/MenuItemButton";
import Modal from "components/modal/Modal";
import TextWithTooltip from "components/table/TextWithTooltip";
import { AUTH_WORKFLOW_TEMPLATE_DELETE, AUTH_WORKFLOW_TEMPLATE_MANAGEMENT_VIEW } from "domain/authority";
import { ConnectedWorkflows } from "domain/workflows";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { templateService } from "services/workflows/TemplateService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);
const WorkflowTemplateKebabMenu = (
    props: ConnectedProps<typeof connector> & {
        uuid: string;
        name: string;
        version: string;
        connectedWorkflows: ConnectedWorkflows;
        onDelete: () => void;
        onUpdate: () => void;
        isSubWorkflow: boolean;
        product: string;
    }
): JSX.Element => {
    const { t } = useTranslation();
    const [deleteWarningDialog, setDeleteWarningDialog] = React.useState(false);
    const [errorDialog, setErrorDialog] = React.useState(false);
    const [deleteTemplateModalVisible, setDeleteTemplateModalVisible] = React.useState(false);

    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [okClicked, setOkClicked] = React.useState(false);
    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    const exportTemplate = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        templateService.export(props.uuid, abortController);
    };

    const deleteTemplate = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        templateService
            .deleteTemplate(props.uuid, abortController)
            .then(() => {
                setOkClicked(false);
                setDeleteTemplateModalVisible(false);
                toast(
                    <SuccessIcon
                        successClass={layout.customToastSuccessIcon}
                        color={theme.contentBackgroundColor}
                        text={t("workflowTemplatesManager.deleteTemplate.successMessage", { name: props.name })}
                    />,
                    {
                        closeButton: (closeToastProps) => (
                            <CustomToastCloseButton closeToast={{ ...closeToastProps }} color={theme.iconFillColor} />
                        ),
                        className: layout.customToastSuccessMessage,
                    }
                );
                props.onDelete();
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setDeleteTemplateModalVisible(false);
                    setErrorDialog(true);
                    setOkClicked(false);
                }
            });
    };
    const handleTemplateDelete = () => {
        setOkClicked(true);
        deleteTemplate();
    };

    return (
        <div className={style.templatesIcon}>
            <Menu className={kebabStyle.kebabMenu}>
                <ul>
                    {userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_TEMPLATE_MANAGEMENT_VIEW]) && (
                        <li>
                            <TextWithTooltip text={t("workflowTemplatesManager.template.tooltipExport")}>
                                <MenuItemButton
                                    onClick={() => {
                                        usageStatisticsService.sendEvent({
                                            category: Category.WORKFLOW_TEMPLATES,
                                            action: Action.EXPORT_WORKFLOW_TEMPLATE,
                                        });
                                        exportTemplate();
                                    }}
                                    data-testid={
                                        testIds.workArea.workflowTemplatesManager.primaryView.table.kebabMenu
                                            .exportButton
                                    }
                                >
                                    <Download color={props.theme.iconFillColor} />
                                </MenuItemButton>
                            </TextWithTooltip>
                        </li>
                    )}
                    {userSessionService.userHasAllAuthorities([AUTH_WORKFLOW_TEMPLATE_DELETE]) && (
                        <li>
                            <TextWithTooltip text={t("workflowTemplatesManager.deleteTemplate.tooltipDelete")}>
                                <MenuItem
                                    tag="button"
                                    onKeyDown={undefined}
                                    onClick={() => {
                                        usageStatisticsService.sendEvent({
                                            category: Category.WORKFLOW_TEMPLATES,
                                            action: Action.REMOVE_WORKFLOW_TEMPLATE,
                                        });

                                        props.connectedWorkflows.parentWorkflows.length
                                            ? setDeleteWarningDialog(true)
                                            : setDeleteTemplateModalVisible(true);
                                    }}
                                    data-testid={testIds.workArea.workflows.table.kebabMenu.deleteButton}
                                >
                                    <Delete color={props.theme.iconFillColor} />
                                </MenuItem>
                            </TextWithTooltip>
                        </li>
                    )}
                </ul>
            </Menu>

            <Modal
                isOpen={deleteWarningDialog}
                hideModal={() => {
                    setDeleteWarningDialog(false);
                }}
                modalTitle={
                    props.isSubWorkflow
                        ? t("workflowTemplatesManager.subWorkflows.deleteSubWorkflowWarningDialog.title")
                        : t("workflowTemplatesManager.deleteTemplate.deleteTemplateWarningDialog.title")
                }
                titleIcon={<TitleIcon iconType={"WARNING"} />}
            >
                <>
                    <div className={style.editMessage}>
                        {t("workflowTemplatesManager.deleteTemplate.deleteTemplateWarningDialog.message")}:
                    </div>
                    <div className={style.listBox}>
                        <ul>
                            {props.connectedWorkflows.parentWorkflows.map((each) => (
                                <li key={each}>{each}</li>
                            ))}
                        </ul>
                    </div>
                    <div className={style.okWarningButton}>
                        <button
                            className={classNames(buttons.primaryButton, buttons.medium, style.okButton)}
                            onClick={() => {
                                setDeleteWarningDialog(false);
                            }}
                            data-testid={testIds.common.confirmationDialog.confirmButton}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </>
            </Modal>
            <DeleteConfirmationDialog
                modalDisplayed={deleteTemplateModalVisible}
                introductionMessage={
                    <div>
                        <p>
                            {t("workflowTemplatesManager.deleteTemplate.introductionMessagePre")}
                            <b>{props.name}</b>
                            {t("workflowTemplatesManager.deleteTemplate.introductionMessagePost")}
                        </p>
                    </div>
                }
                confirmationMessage={t("workflowTemplatesManager.deleteTemplate.confirmationMessage")}
                handleCommand={handleTemplateDelete}
                setModalDisplayed={() => setDeleteTemplateModalVisible(false)}
                title={t("workflowTemplatesManager.deleteTemplate.title")}
                loading={okClicked}
            />
            <AlertDialog
                isOpen={errorDialog}
                hideModal={() => {
                    setErrorDialog(false);
                }}
                type={"ERROR"}
                headingText={
                    props.isSubWorkflow
                        ? t("workflowTemplatesManager.subWorkflows.failureTitle")
                        : t("workflowTemplatesManager.deleteTemplate.failureTitle")
                }
                primaryButton={{
                    text: t("Common.deleteAgain"),
                    action: () => {
                        setErrorDialog(false);
                        setDeleteTemplateModalVisible(true);
                    },
                }}
                secondaryButton={{
                    action: () => {
                        setErrorDialog(false);
                    },
                    text: t("Common.cancel"),
                }}
            />
        </div>
    );
};

export default connector(WorkflowTemplateKebabMenu);
