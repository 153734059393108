import classNames from "classnames";
import { useFeature } from "flagged";
import { ErrorMessage, Field, Formik, Form as FormikForm, FormikProps } from "formik";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import AdvancedSearchView from "./advanced-search/AdvancedSearchView";
import { validateFilterGroup } from "./advanced-search/validation";
import style from "./create-report-view-form.scss";
import Swap from "./Swap";
import { AUTH_CUSTOM_REPORT_VIEW_CREATE_OWN, AUTH_CUSTOM_REPORT_VIEW_CREATE_SHARED } from "domain/authority";
import { CustomReportViewFilterGroup, isFilterGroup, MAX_COLUMN_COUNT } from "domain/reports";
import { FLAG_ADVANCE_UI_SEARCH } from "services/feature/FeatureFlagService";
import { createTranslatePath } from "services/pathTranslator";
import { CreatedReportViewWithAdvancedSearch, ReportPath } from "services/report/ReportViewService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import form from "styles/form.scss";

import testIds from "testIds.json";

interface Props {
    name?: string;
    shared?: boolean;
    columns?: string[];
    own?: boolean;
    handleSubmit: (view: CreatedReportViewWithAdvancedSearch) => Promise<void>;
    handleCancel: () => void;
    paths: ReportPath[];
    create: boolean;
    edit?: boolean;
    filters?: CustomReportViewFilterGroup;
    formId?: string;
    disableSubmit: (disabled: boolean) => void;
    validFilterCheck: (setAllFiltersValid: boolean) => void;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

interface FormError {
    name?: string;
    shared?: string;
    columns?: string;
    filters?: string;
}
function countFilters(filterGroup?: CustomReportViewFilterGroup): number {
    let count = 0;
    if (filterGroup == null || filterGroup.filters == null) {
        return count;
    }
    for (const each of filterGroup.filters) {
        if (isFilterGroup(each)) {
            count += countFilters(each);
        } else {
            count += 1;
        }
    }
    return count;
}

export const MAXIMUM_FILTER_COUNT = 20;

const ManageReportViewForm = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const translatePath = createTranslatePath(useTranslation);
    const disablePrivateCheckbox =
        !userSessionService.userHasAllAuthorities([
            AUTH_CUSTOM_REPORT_VIEW_CREATE_SHARED,
            AUTH_CUSTOM_REPORT_VIEW_CREATE_OWN,
        ]) || props.own === false;
    const defaultValueForShared = props.shared != null ? !props.shared : true;
    const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
    // Strictly speaking not only "too many" but also as many as is allowed.
    const [tooManyFilters, setTooManyFilters] = React.useState(countFilters(props.filters) >= MAXIMUM_FILTER_COUNT);
    const [visibleFilterValidationErrors, setVisibleFilterValidationErrors] = React.useState<boolean>(false);

    return (
        <>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    name: props.name != undefined ? props.name : "",
                    shared: defaultValueForShared,
                    columns: props.columns != undefined ? props.columns : [],
                    filters: props.filters,
                }}
                onSubmit={(view) => {
                    props.handleSubmit(view);
                }}
                validate={(values: CreatedReportViewWithAdvancedSearch) => {
                    const formError: FormError = {};
                    if (values.name.length < 2 || values.name.length > 32) {
                        formError["name"] = t("CreateReportView.form.nameRequired");
                        props.validFilterCheck(true);
                    } else {
                        props.validFilterCheck(false);
                    }
                    if (!validateFilterGroup(true, values.filters)) {
                        // The value itself doesn't matter because the actual validation errors are shown elsewhere
                        // with proper localized messages. Here it just needs to be defined.
                        formError.filters = "bad filters";
                    }
                    if (values.name.trim()) {
                        setVisibleFilterValidationErrors(false);
                    }

                    return formError;
                }}
                validateOnChange={true}
                validateOnBlur={true}
            >
                {({
                    isSubmitting,
                    dirty,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                    values,
                    touched,
                }: FormikProps<CreatedReportViewWithAdvancedSearch>) => {
                    function updateColumns(paths: string[]) {
                        setFieldValue("columns", paths, true);
                    }

                    function updateFilters(filters?: CustomReportViewFilterGroup) {
                        setFieldValue("filters", filters, true);
                        setTooManyFilters(countFilters(filters) >= MAXIMUM_FILTER_COUNT);
                    }
                    if (props.disableSubmit) {
                        props.disableSubmit(isSubmitting || !dirty);
                    }

                    let validateName = false;

                    if (values.name.length == 0 && touched.name) {
                        validateName = true;
                        props.validFilterCheck(true);
                    } else {
                        validateName = false;
                        props.validFilterCheck(false);
                    }
                    // The value (touched.filters) is never truly null or a boolean. It's an object but the object
                    // is only created when submit button has been clicked. Up until that time "filters" property
                    // doesn't exist in "touched" object. This way we know when we should show validation errors
                    // for the existing filters, if any.
                    // const visibleFilterValidationErrors = touched.filters != null;

                    return (
                        <FormikForm id={props.formId}>
                            <div className={form.formFields}>
                                <label htmlFor={"name"} className={form.label}>
                                    {t("Common.nameWithColon")}
                                </label>
                                <Field
                                    id={"name"}
                                    name={"name"}
                                    type={"text"}
                                    className={classNames(form.input, form.fixedWidthInput)}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    data-testid={
                                        testIds.workArea.report.manageReportViewDialog.tabs.general.nameInput.itself
                                    }
                                ></Field>
                                <div className={classNames(form.error, style.error)}>
                                    <ErrorMessage
                                        name="name"
                                        data-testid={
                                            testIds.workArea.report.manageReportViewDialog.tabs.general.nameInput
                                                .errorLabel
                                        }
                                    />
                                </div>
                            </div>

                            <div className={form.formFields}>
                                <div className={form.checkboxWithInfoContainer}>
                                    <div className={form.checkboxAlignedLabelContainer}>
                                        <label htmlFor={"name"} className={form.label}>
                                            {t("CreateReportView.form.private")}
                                        </label>
                                    </div>
                                    <div className={form.checkboxWithInfo}>
                                        <label className={form.container}>
                                            <input
                                                id={"shared"}
                                                name={"shared"}
                                                type={"checkbox"}
                                                data-testid={
                                                    testIds.workArea.report.manageReportViewDialog.tabs.general
                                                        .privateCheckbox
                                                }
                                                defaultChecked={defaultValueForShared}
                                                disabled={disablePrivateCheckbox}
                                                onChange={(event: React.ChangeEvent) => {
                                                    usageStatisticsService.sendEvent({
                                                        category: Category.REPORT_VIEW,
                                                        action: Action.CHANGE_PRIVATE,
                                                    });
                                                    setFieldValue("shared", values.shared, true);
                                                    return handleChange(event);
                                                }}
                                            />
                                            <span className={form.checkmark} />
                                        </label>
                                        {disablePrivateCheckbox && (
                                            <div className={style.disabledCheckboxInfoText}>
                                                {t("CreateReportView.form.disabledPrivateInfo")}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <Tabs
                                className={style.tabsSegmentVariant}
                                selectedIndex={selectedTabIndex}
                                onSelect={(index) => setSelectedTabIndex(index)}
                            >
                                <TabList>
                                    <Tab
                                        data-testid={testIds.workArea.report.manageReportViewDialog.tabs.general.itself}
                                    >
                                        {t("CreateReportView.form.columnsTab")}
                                    </Tab>
                                    <Tab
                                        data-testid={testIds.workArea.report.manageReportViewDialog.tabs.filters.itself}
                                    >
                                        {t("CreateReportView.form.filtersTab")}
                                    </Tab>
                                </TabList>
                                <TabPanel>
                                    <Swap
                                        maximumSelectedCount={MAX_COLUMN_COUNT}
                                        onChange={updateColumns}
                                        paths={props.paths}
                                        t={t}
                                        create={props.create}
                                        edit={props.edit}
                                        columns={values.columns}
                                        theme={props.theme}
                                        translatePath={translatePath}
                                    />
                                </TabPanel>
                                <TabPanel>
                                    <div className={form.formFields}>
                                        <div>
                                            {useFeature(FLAG_ADVANCE_UI_SEARCH) && (
                                                <AdvancedSearchView
                                                    paths={props.paths}
                                                    t={t}
                                                    create={props.create}
                                                    filters={values.filters}
                                                    preventAdditionalFilters={tooManyFilters}
                                                    visibleFilterValidationErrors={visibleFilterValidationErrors}
                                                    theme={props.theme}
                                                    translatePath={translatePath}
                                                    update={updateFilters}
                                                    validFilterCheck={props.validFilterCheck}
                                                    validateName={validateName}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </FormikForm>
                    );
                }}
            </Formik>
        </>
    );
};

export default connector(ManageReportViewForm);
