import { EmsConfiguration, EmsConfigurationDto, LicenseDelivery, toEmsConfigurationDto } from "domain/licenses";
import {
    CombinedTier,
    CommonTenant,
    FetchRegionsListResponse,
    LicensingModel,
    LoginMethod,
    Tenant,
    TenantCursor,
    TenantsData,
    TenantType,
} from "domain/tenants";
import { FeatureLicenseType, RoleDto, toFeatureLicenseType, toRole } from "domain/users";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";
import { LicenseDeliveryDto, toLicenseDelivery } from "services/licenses/LicenseService";
import { ParameterQuery, trimToUndefined } from "utils/commonFunctions";

export interface TenantLicense {
    identifier: string;
    assigned: number;
    used: number;
    type: string;
    expiration: string;
}

export interface LicenseTokenRates {
    name: string;
    uuid: string;
    product_to_rate: [
        {
            productId: string;
            rate: number;
        }
    ];
}

interface TenantDto extends CommonTenant {
    deletion_status: boolean;
    contact_email: string;
    ems_id: string;
    contact_name: string;
    country_code: string;
    created_by: string;
    created_timestamp: string;
    erasure_client_endpoint: string;
    public_api_endpoint: string;
    usage_statistics: boolean;
    roles?: RoleDto[];
    expiration_date: string;
    region: string;
    status: boolean;
    tier: CombinedTier;
    salesforce_id: string;
    type: TenantType;
    total_reports_count: string;
    total_users: string;
    total_workflows: string;
    products_licensed: [];
    total_available_licenses: "Total sum of available licenses";
    parent_expiration_date: string;
    login_method?: LoginMethod;
    level: number;
    tenants?: TenantDto[];
    parent_uuid: string;
    found_user?: string;
    found_user_name?: string;
    sub_tenants_count: number;
    licensing_model: LicensingModel;
    token_rate?: LicenseTokenRates;
    ip_restrictions: [];
    parent_licensing_model: LicensingModel;
    parent_type: TenantType;
    parent_tier: CombinedTier;
}

export interface TenantsDto {
    tenants: TenantDto[];
    cursor: string;
}

interface AccessTenantResponseDto {
    message: string;
    stan: string;
    laurel: string;
    arthur: string;
    oliver: string;
    publicApi: string;
    publicApiDocumentation: string;
    feature_licenses: string[];
    region?: string;
    tenant_type: TenantType;
    tenant_name: string;
    login_method?: LoginMethod;
    licensing_model: LicensingModel;
    tenant_tier: string;
}

interface AccessTenantResponse {
    message: string;
    stan: string;
    laurel: string;
    arthur: string;
    oliver: string;
    publicApi: string;
    publicApiDocumentation: string;
    featureLicenses: FeatureLicenseType[];
    region?: string;
    type: TenantType;
    tenantName: string;
    loginMethod?: LoginMethod;
    licensingModel: LicensingModel;
    tenantTier: string;
}

export interface EditTenantDto {
    tenantStatus: boolean | null;
    emsConfiguration: EmsConfiguration;
    expirationDate: string;
    customerName?: string | null;
    countryCode?: string | null;
    contactName?: string | null;
    contactEmail?: string | null;
    notes?: string | null;
    salesforceId?: string | null;
    tier: CombinedTier;
    licensingModel: LicensingModel;
    licenseDelivery: LicenseDelivery | null;
    type: string;
}

export interface EditTenantRequest {
    name?: string;
    status?: string | boolean;
    ems: EmsConfigurationDto;
    expiration_date: string;
    customer_name?: string;
    country_code?: string;
    contact_name?: string;
    contact_email?: string;
    salesforce_id?: string;
    notes?: string;
    tier: CombinedTier;
    licensing_model?: LicensingModel;
    license_delivery?: LicenseDeliveryDto | null;
    type?: string;
}

export interface CreateTenantResponse {
    message: string;
    warnings: string[] | undefined;
}

export interface TenantHierarchyResponse {
    uuid: string;
    name: string;
    tenants: TenantHierarchyResponse[];
}

export interface TenantDeletionNotificationDataDto {
    tenant_name: string;
}

export interface TenantDeletionNotificationData {
    tenantName: string;
}

function toTenant(dto: TenantDto): Tenant {
    return {
        name: dto.name,
        notes: dto.notes,
        region: dto.region,
        uuid: dto.uuid,
        countryCode: dto.country_code,
        contactName: dto.contact_name,
        contactEmail: dto.contact_email,
        emsId: dto.ems_id,
        createdTimestamp: dto.created_timestamp,
        createdBy: dto.created_by,
        erasureClientEndpoint: dto.erasure_client_endpoint,
        publicApiEndpoint: dto.public_api_endpoint,
        usageStatistics: dto.usage_statistics,
        status: dto.status,
        roles: (dto.roles || []).map(toRole),
        expirationDate: dto.expiration_date,
        tier: dto.tier,
        salesforceAccountId: dto.salesforce_id,
        type: dto.type,
        totalReportsCount: dto.total_reports_count,
        totalUsers: dto.total_users,
        totalWorkflows: dto.total_workflows,
        productsLicensed: dto.products_licensed,
        availableLicenses: dto.total_available_licenses,
        parentExpirationDate: dto.parent_expiration_date,
        loginMethod: dto.login_method,
        deletionStatus: dto.deletion_status,
        tenantHierarchyLevel: dto.level,
        tenants: (dto.tenants || []).map(toTenant),
        parentUuid: dto.parent_uuid,
        foundUser: dto.found_user,
        foundUserName: dto.found_user_name,
        subTenantsCount: dto.sub_tenants_count,
        licensingModel: dto.licensing_model,
        ipRestrictions: dto.ip_restrictions,
        parentLicensingModel: dto.parent_licensing_model,
        parentType: dto.parent_type,
        parentTier: dto.parent_tier,
    };
}

function toKeyCursor(cursor: string | null): TenantCursor | null {
    return cursor == null ? null : JSON.parse(cursor);
}

export function toTenantDeletionNotificationData(
    dto: TenantDeletionNotificationDataDto
): TenantDeletionNotificationData {
    return {
        tenantName: dto.tenant_name,
    };
}

const PATH_TENANT = "/api/tenants";
const PATH_V1_TENANT = "/api/v1/tenants";

function toAccessTenantResponse(dto: AccessTenantResponseDto) {
    const featureLicenses: FeatureLicenseType[] = dto.feature_licenses.map((l) => toFeatureLicenseType(l));
    return {
        message: dto.message,
        stan: dto.stan,
        laurel: dto.laurel,
        arthur: dto.arthur,
        oliver: dto.oliver,
        publicApi: dto.publicApi,
        publicApiDocumentation: dto.publicApiDocumentation,
        featureLicenses: featureLicenses,
        region: dto.region,
        type: dto.tenant_type,
        tenantName: dto.tenant_name,
        loginMethod: dto.login_method,
        licensingModel: dto.licensing_model,
        tenantTier: dto.tenant_tier,
    };
}

export class TenantService {
    public fetchTenants(
        abortController: AbortController,
        cursor: TenantCursor | null,
        search?: string,
        username?: string,
        all?: string
    ): Promise<TenantsData> {
        const apiUrl =
            PATH_TENANT +
            new ParameterQuery()
                .add("search", search)
                .add("username", username)
                .add("all", all)
                .add("cursor", cursor == null ? "" : JSON.stringify(cursor))
                .createQueryString();
        return apiGatewayService
            .invokeApi(apiUrl, "GET", null, { abortController: abortController, apiType: ApiType.LAUREL })
            .then((response: TenantsDto) => {
                const tenantList = response.tenants.map(toTenant);
                const cursor = toKeyCursor(response.cursor);
                return {
                    tenantList: {
                        tenantList: tenantList,
                        cursor: cursor,
                    },
                };
            });
    }

    public createTenant(
        name: string,
        type: string,
        region: string,
        tier: CombinedTier,
        countryCode: string,
        notes: string,
        contactName: string,
        contactEmail: string,
        expirationDate: string,
        salesforceAccountId: string,
        ems: EmsConfiguration,
        parentUuid: string | undefined,
        licenseDelivery: LicenseDelivery,
        licensingModel: LicensingModel,
        abortController: AbortController
    ): Promise<CreateTenantResponse> {
        return apiGatewayService.invokeApi(
            PATH_TENANT,
            "POST",
            {
                name,
                type,
                region,
                tier,
                country_code: countryCode,
                notes,
                contact_name: contactName,
                contact_email: contactEmail,
                salesforce_id: salesforceAccountId,
                ems: toEmsConfigurationDto(ems),
                license_delivery: toLicenseDelivery(licenseDelivery),
                expiration_date: expirationDate,
                parent_uuid: parentUuid,
                licensing_model: licensingModel,
            },
            { abortController: abortController, apiType: ApiType.LAUREL }
        );
    }

    public accessTenant(uuid: string, abortController: AbortController): Promise<AccessTenantResponse> {
        return apiGatewayService
            .invokeApi(PATH_TENANT + "/" + uuid + "/access", "GET", null, {
                abortController: abortController,
                apiType: ApiType.LAUREL,
            })
            .then((dto: AccessTenantResponseDto) => toAccessTenantResponse(dto));
    }

    public fetchTenant(uuid: string, abortController?: AbortController): Promise<Tenant> {
        return apiGatewayService
            .invokeApi(PATH_TENANT + "/" + uuid, "GET", null, {
                abortController: abortController,
                apiType: ApiType.LAUREL,
            })
            .then((dtos: TenantDto) => toTenant(dtos));
    }

    public fetchRegionsList(): Promise<FetchRegionsListResponse> {
        return apiGatewayService.invokeApi("/regions", "GET", null);
    }

    public updateTenantSettings(
        uuid: string,
        statisticsEnabled: boolean | undefined,
        loginMethod: LoginMethod | undefined,
        ipRestrictions: string[] | undefined,
        abortController: AbortController
    ): Promise<void> {
        const api_url = PATH_V1_TENANT + "/" + uuid + "/settings";
        return apiGatewayService.invokeApi(
            api_url,
            "POST",
            {
                statistics_enabled: statisticsEnabled == null ? undefined : statisticsEnabled,
                login_method: trimToUndefined(loginMethod),
                ip_restrictions: ipRestrictions,
            },
            {
                abortController: abortController,
                apiType: ApiType.ARTHUR,
                emptyResponse: true,
            }
        );
    }

    public editTenant(
        uuid: string,
        editTenant: EditTenantDto,
        abortController: AbortController
    ): Promise<CreateTenantResponse> {
        const postData: EditTenantRequest = {
            tier: editTenant.tier,
            ems: toEmsConfigurationDto(editTenant.emsConfiguration),
            expiration_date: editTenant.expirationDate,
            license_delivery: toLicenseDelivery(editTenant.licenseDelivery),
        };

        if (editTenant.tenantStatus != null) {
            postData.status = editTenant.tenantStatus;
        }
        if (editTenant.customerName != null) {
            postData.name = editTenant.customerName;
        }
        if (editTenant.countryCode != null) {
            postData.country_code = editTenant.countryCode;
        }
        if (editTenant.contactName != null) {
            postData.contact_name = editTenant.contactName;
        }
        if (editTenant.contactEmail != null) {
            postData.contact_email = editTenant.contactEmail;
        }
        if (editTenant.notes != null) {
            postData.notes = editTenant.notes;
        }
        if (editTenant.salesforceId != null) {
            postData.salesforce_id = editTenant.salesforceId;
        }

        if (editTenant.type != null) {
            postData.type = editTenant.type;
        }
        if (editTenant.licensingModel != null) {
            postData.licensing_model = editTenant.licensingModel;
        }
        return apiGatewayService.invokeApi(PATH_TENANT + "/" + uuid, "POST", postData, {
            abortController: abortController,
            apiType: ApiType.LAUREL,
        });
    }

    public uploadLogo(logo: string, abortController: AbortController): Promise<void> {
        const postData = { image: logo };
        return apiGatewayService.invokeApi("/api/tenant-info/logo", "POST", postData, {
            abortController: abortController,
            apiType: ApiType.OLIVER,
            emptyResponse: true,
        });
    }

    public fetchHierarchy(tenantUuid: string, abortController: AbortController): Promise<TenantHierarchyResponse> {
        return apiGatewayService.invokeApi("/api/v1/tenants/" + tenantUuid + "/hierarchy", "GET", null, {
            abortController: abortController,
            apiType: ApiType.LAUREL,
        });
    }

    public deleteTenant(tenantUuid: string, region: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi("/api/v1/tenant-info/" + tenantUuid, "DELETE", null, {
            abortController: abortController,
            apiType: ApiType.OLIVER,
            emptyResponse: true,
            region: region,
        });
    }

    public tenantDeleteStatusUpdate(tenantUuid: string, abortController: AbortController): Promise<void> {
        return apiGatewayService.invokeApi("/api/v1/tenant-info/" + tenantUuid + "/deletion-status", "POST", null, {
            abortController: abortController,
            apiType: ApiType.OLIVER,
            emptyResponse: true,
        });
    }

    public fetchSubTenants(parentUuid: string, abortController: AbortController): Promise<TenantsData> {
        const apiUrl = PATH_TENANT + new ParameterQuery().add("parent_uuid", parentUuid).createQueryString();
        return apiGatewayService
            .invokeApi(apiUrl, "GET", null, { abortController: abortController, apiType: ApiType.LAUREL })
            .then((response: TenantsDto) => {
                const tenantList = response.tenants.map(toTenant);
                const cursor = toKeyCursor(response.cursor);
                return {
                    tenantList: {
                        tenantList: tenantList,
                        cursor: cursor,
                    },
                };
            });
    }
}

export const tenantService = new TenantService();
