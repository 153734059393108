import { TFunction } from "i18next";
import * as React from "react";

import FilterGroup from "components/reports/advanced-search/FilterGroup";
import { CustomReportViewFilterGroup } from "domain/reports";
import { UsersData } from "domain/users";
import { ReportPath } from "services/report/ReportViewService";
import defaultColor from "styles/colors/default-color.scss";
import tenantColor from "styles/colors/tenant-color.scss";

interface Props {
    paths: ReportPath[];
    t: TFunction;
    theme: typeof defaultColor | typeof tenantColor;
    translatePath: (path: string) => string;
    create: boolean;
    filters?: CustomReportViewFilterGroup;
    preventAdditionalFilters: boolean;
    update: (filters?: CustomReportViewFilterGroup) => void;
    visibleFilterValidationErrors: boolean;
    validFilterCheck: (setAllFiltersValid: boolean) => void;
    validateName: boolean;
}

export interface State {
    usersList: UsersData;
}

export default class AdvancedSearchView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.setUsersList = this.setUsersList.bind(this);
        this.state = {
            usersList: {
                cursor: "",
                count: -1,
                userTableData: [],
            },
        };
    }

    setUsersList(usersList: UsersData) {
        this.setState({
            usersList,
        });
    }

    initialGroup: CustomReportViewFilterGroup = {
        ...this.props.filters,
        filters: [],
    };

    render(): JSX.Element {
        return (
            <div>
                <div>
                    <FilterGroup
                        filter={this.props.filters || this.initialGroup}
                        first={true}
                        paths={this.props.paths}
                        preventAdditionalFilters={this.props.preventAdditionalFilters}
                        t={this.props.t}
                        theme={this.props.theme}
                        translatePath={this.props.translatePath}
                        update={(filters?: CustomReportViewFilterGroup) => this.props.update(filters)}
                        visibleValidationErrors={this.props.visibleFilterValidationErrors}
                        state={this.state}
                        setStateUsersList={this.setUsersList}
                        groupDepth={0}
                        validFilterCheck={this.props.validFilterCheck}
                        validateName={this.props.validateName}
                    />
                </div>
            </div>
        );
    }
}
