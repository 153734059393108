import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import style from "./view-delivery-history.scss";
import PreviewIcon from "components/icons/PreviewIcon";
import BundleTokenPricingView from "components/licenses/BundleTokenPricingView";
import { createLicenseTypes, getDeliveryTypes } from "components/licenses/common";
import tokenstyle from "components/licenses/delivery-history/add-license-delivery.scss";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import StaticTable from "components/support/api-guide/StaticTable";
import DateCell from "components/table/DateCell";
import { deduceTierLicenses } from "components/tenants/add-tenant/AddTenantForm";
import { LicenseDeliveryHistory } from "domain/licenses";
import {
    extractLatestLicenseRateUuid,
    extractProductRatesList,
    fetchLicenseRateVersion,
    ProductToRateList,
} from "services/licenses/LicenseService";
import { isLicensingModelBundleWithToken, isUserParentInternal } from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import { replaceProductIdWithNameInRatesTable } from "store/license";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

export function createProductsMap(): Map<string, string> {
    const licenses = createLicenseTypes(true);
    return new Map<string, string>(licenses.map((each) => [each.productId, each.productName]));
}

export function fetchRateVersionNameFromUuid(rateUuid: string | undefined, tokenRatesLists: ProductToRateList[]) {
    let rateVersionName = "";
    for (const rate of tokenRatesLists) {
        if (rate.uuid === rateUuid) {
            rateVersionName = rate.name;
        }
    }
    return rateVersionName;
}

export const ViewDeliveryHistory = (props: {
    deliveryHistoryDetails: LicenseDeliveryHistory;
    loading: boolean;
    tenantTier: string;
}): JSX.Element => {
    const { t } = useTranslation();
    const DELIVERY_TYPES = getDeliveryTypes();
    const ALL_PRODUCTS = createProductsMap();
    const [bundleTokenPricingModalVisible, setBundleTokenPricingModalVisible] = React.useState(false);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const deducedTierLicenses = deduceTierLicenses(props.tenantTier, true, true);
    const [tokenRateVersions, setTokenRateVersions] = React.useState<ProductToRateList[]>([]);
    const [rateUuid, setTokenRateUUid] = React.useState<string>("");

    async function fetchRatesFromApi() {
        const rates = await fetchLicenseRateVersion();
        setTokenRateVersions(rates);
        setTokenRateUUid(extractLatestLicenseRateUuid(rates));
    }
    React.useEffect(() => {
        fetchRatesFromApi();
    }, []);

    return props.loading ? (
        <div>
            <LoadingIndicator />
        </div>
    ) : (
        <>
            <div className={style.deliveryHistoryDetails}>
                {props.deliveryHistoryDetails.type && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.type")}</div>
                        <div
                            data-testid={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.deliveryTypeLabel}
                        >
                            {DELIVERY_TYPES.get(props.deliveryHistoryDetails.type)}
                        </div>
                    </>
                )}

                {props.deliveryHistoryDetails.opportunityId && isUserParentInternal() && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.opportunityId")}</div>
                        <div
                            data-testid={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.opportunityIdLabel}
                        >
                            {props.deliveryHistoryDetails.opportunityId}
                        </div>
                    </>
                )}

                {props.deliveryHistoryDetails.caseNumber && isUserParentInternal() && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.caseNumber")}</div>
                        <div data-testid={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.caseNumberLabel}>
                            {props.deliveryHistoryDetails.caseNumber}
                        </div>
                    </>
                )}

                {props.deliveryHistoryDetails.licenseRateVersion &&
                    isUserParentInternal() &&
                    isLicensingModelBundleWithToken() && (
                        <>
                            <div className={style.label}>
                                {t("DeliveryHistory.deliveryHistoryDialog.licenseRateVersion")}
                            </div>
                            <div className={form.formFieldsFlex}>
                                <div
                                    data-testid={
                                        testIds.workArea.license.deliveryHistory.viewDeliveryDialog
                                            .licenseRateVersionLabel
                                    }
                                >
                                    {props.deliveryHistoryDetails.licenseRateVersion}
                                </div>
                                <div>
                                    <button
                                        className={classNames(tokenstyle.tooltipPosition, buttons.textButton)}
                                        type="button"
                                        onClick={() => {
                                            setBundleTokenPricingModalVisible(true);
                                        }}
                                    >
                                        <div>
                                            <PreviewIcon color={theme.linkTextColor} />
                                            <span className={tokenstyle.addMoreButton}>
                                                {t("DeliveryHistory.addLicenseDelivery.previewRateTable")}
                                            </span>
                                        </div>
                                    </button>
                                </div>
                                <BundleTokenPricingView
                                    visibility={bundleTokenPricingModalVisible}
                                    setVisibility={setBundleTokenPricingModalVisible}
                                    productToRate={replaceProductIdWithNameInRatesTable(
                                        extractProductRatesList(tokenRateVersions, rateUuid),
                                        deducedTierLicenses
                                    )}
                                />
                            </div>
                        </>
                    )}

                {props.deliveryHistoryDetails.notes && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.notes")}</div>
                        <div data-testid={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.notesLabel}>
                            {props.deliveryHistoryDetails.notes}
                        </div>
                    </>
                )}

                {props.deliveryHistoryDetails.creationDate && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.creationDate")}</div>
                        <div>
                            <DateCell
                                tooltip={false}
                                value={props.deliveryHistoryDetails.creationDate}
                                withoutTime={true}
                                testId={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.creationDateLabel}
                            />
                        </div>
                    </>
                )}

                {props.deliveryHistoryDetails.creatorUuid && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.creatorUuid")}</div>
                        <div data-testid={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.creatorUuidLabel}>
                            {props.deliveryHistoryDetails.creatorUuid}
                        </div>
                    </>
                )}

                {props.deliveryHistoryDetails.editDate && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.editDate")}</div>
                        <div>
                            <DateCell
                                tooltip={false}
                                value={props.deliveryHistoryDetails.editDate}
                                withoutTime={true}
                                testId={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.editDateLabel}
                            />
                        </div>
                    </>
                )}

                {props.deliveryHistoryDetails.editorUuid && (
                    <>
                        <div className={style.label}>{t("DeliveryHistory.deliveryHistoryDialog.editorUuid")}</div>
                        <div data-testid={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.editorUuidLabel}>
                            {props.deliveryHistoryDetails.editorUuid}
                        </div>
                    </>
                )}
            </div>
            <StaticTable
                headers={[
                    {
                        value: t("DeliveryHistory.deliveryHistoryDialog.table.licenseType"),
                    },
                    {
                        value: t("DeliveryHistory.deliveryHistoryDialog.table.totalOfLicenses"),
                    },
                    {
                        value: t("DeliveryHistory.deliveryHistoryDialog.table.expirationDate"),
                    },
                ]}
                cells={
                    props.deliveryHistoryDetails.licenses.length > 0
                        ? props.deliveryHistoryDetails.licenses.map((license, key) => {
                              return [
                                  <div
                                      data-testid={
                                          testIds.workArea.license.deliveryHistory.viewDeliveryDialog.table
                                              .licenseTypeLabel
                                      }
                                      key={license.licenseId}
                                      className={style.label}
                                  >
                                      {ALL_PRODUCTS.get(license.licenseId)}
                                  </div>,
                                  <div
                                      data-testid={
                                          testIds.workArea.license.deliveryHistory.viewDeliveryDialog.table
                                              .totalOfLicensesLabel
                                      }
                                      key={"amount" + key}
                                      className={style.label}
                                  >
                                      {license.amount}
                                  </div>,
                                  <div key={"date" + key} className={style.label}>
                                      <DateCell
                                          tooltip={false}
                                          value={license.expirationDate}
                                          testId={
                                              testIds.workArea.license.deliveryHistory.viewDeliveryDialog.table
                                                  .expirationDateLabel
                                          }
                                      />
                                  </div>,
                              ];
                          })
                        : []
                }
                testId={testIds.workArea.license.deliveryHistory.viewDeliveryDialog.table.itself}
                emptyStateMessage={t("LicenseEvent.filter.noDataAvailable")}
            />
        </>
    );
};
