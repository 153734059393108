import classNames from "classnames";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ManageReportViewForm from "./ManageReportViewForm";
import style from "./report-view.scss";
import { ButtonContainer } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import FailedRedNotificationIcon from "components/icons/FailedRedNotificationIcon";
import { SubpageLayout } from "components/layout/subpage-layout/SubpageLayout";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import { enableCustomReportViewRoutes } from "components/router/Routes";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { CustomReportView, CustomReportViewFilterGroup } from "domain/reports";
import {
    CreatedReportViewWithAdvancedSearch,
    CreateReportViewResponse,
    ReportPath,
    reportViewService,
} from "services/report/ReportViewService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";

import testIds from "testIds.json";

export interface TableState {
    cursor: string[];
    scrollPosition: number;
    owned: boolean;
    total: number;
}

interface Result {
    title: string;
    message: string;
}

interface Props {
    handleVisibility: (visible: boolean) => void;
    paths: ReportPath[];
    visible: boolean;
    name?: string;
    shared?: boolean;
    columns?: string[];
    create: boolean;
    filters?: CustomReportViewFilterGroup;
    viewDetails: CustomReportView;
}

const ERROR_CODE_WITH_PARAMETER = "DUPLICATE_NAME";
const ERROR_CODE_TO_TRANSLATION_KEY_MAP = new Map<string, string>();
const ERROR_TRANSLATION_KEY_PREFIX = "CreateReportView.confirmationDialog.error.messages.";
[
    ["INVALID_BODY", "invalidBody"],
    ["MISSING_FIELD_NAME", "missingName"],
    ["INVALID_FIELD_NAME", "invalidName"],
    ["MISSING_FIELD_SHARING", "missingSharing"],
    ["INVALID_FIELD_SHARING", "invalidSharing"],
    [ERROR_CODE_WITH_PARAMETER, "duplicateName"],
].forEach((pair) => {
    ERROR_CODE_TO_TRANSLATION_KEY_MAP.set(pair[0], ERROR_TRANSLATION_KEY_PREFIX + pair[1]);
});

export default function ManageReportViewModal(props: Props): JSX.Element {
    const [create, setCreate] = React.useState(props.create);
    const { t } = useTranslation();
    const [resultModalVisible, setResultModalVisible] = React.useState(false);
    const [success, setSuccess] = React.useState(false);
    const [redirectUuid, setRedirectUuid] = React.useState("");
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [result, setResult] = React.useState<Result>({
        title: "",
        message: "",
    });
    const [redirectFailure, setRedirectFailure] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [disableSubmit, setDisableSubmit] = React.useState(create ? false : true);
    const [allFiltersValid, setAllFiltersValid] = React.useState(false);
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);

    const formIdForSubmit = "createCustomView";
    const viewTitle = create ? t("Common.createNewView") : t("EditReportView.title", { name: props.name });
    const defaultColumns = ["uuid", "product", "date"];

    const hideFormModal = () => {
        setCreate(props.create);
        props.handleVisibility(false);
    };

    const showFormModal = () => {
        props.handleVisibility(true);
    };

    const fetchViewsAndRedirect = (viewUuid: string) => {
        setRedirectFailure(false);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportViewService
            .fetchViews(abortController)
            .then((data) => {
                enableCustomReportViewRoutes(data.reportViews);
                window.location.replace("/reports/ourViews/" + viewUuid);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    showResult({
                        title: t("Common.failure"),
                        message: t("CreateReportView.confirmationDialog.error.messages.fetchViewsFailure"),
                    });
                }
                setRedirectFailure(true);
            });
    };

    const hideResultModal = () => {
        setResultModalVisible(false);
        if (redirectFailure) {
            return;
        }
        redirectUuid != "" && success ? fetchViewsAndRedirect(redirectUuid) : showFormModal();
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultModalVisible(true);
    };

    const toErrorMessage = (errorCode: string, name: string) => {
        const genericMessage = t("CreateReportView.confirmationDialog.error.messages.genericError");
        if (!errorCode) {
            return genericMessage;
        }
        const translationKey = ERROR_CODE_TO_TRANSLATION_KEY_MAP.get(errorCode);
        if (!translationKey) {
            return genericMessage;
        }
        return errorCode === ERROR_CODE_WITH_PARAMETER ? t(translationKey, { name: name }) : t(translationKey);
    };

    const createView = async (values: CreatedReportViewWithAdvancedSearch): Promise<void> => {
        setLoading(true);
        setResultModalVisible(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        await reportViewService
            .createView(values, abortController)
            .then((response: CreateReportViewResponse) => {
                setLoading(false);
                setSuccess(true);
                setRedirectUuid(response.reportView.uuid);
                showResult({
                    title: t("Common.success"),
                    message: t("CreateReportView.confirmationDialog.success.message", { name: values.name }),
                });
                setCreate(true);
            })
            .catch((e: Error) => {
                setLoading(false);
                if (!abortController.signal.aborted) {
                    const errorResponse = JSON.parse(e.message);
                    setSuccess(false);
                    showResult({
                        title: t("Common.failure"),
                        message: toErrorMessage(errorResponse.error?.code, values.name),
                    });
                }
            });
    };
    const editView = async (reportView: CreatedReportViewWithAdvancedSearch): Promise<void> => {
        setLoading(true);
        setResultModalVisible(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        reportViewService
            .editView(props.viewDetails.uuid, reportView, abortController)
            .then(() => {
                setLoading(false);
                setSuccess(true);
                if (props.viewDetails) {
                    setRedirectUuid(props.viewDetails.uuid);
                }
                setResult({
                    title: t("EditReportView.viewEdit.success.title"),
                    message: t("EditReportView.viewEdit.success.message"),
                });
            })
            .catch(() => {
                setLoading(false);
                if (!abortController.signal.aborted) {
                    setSuccess(false);
                    setResult({
                        title: t("EditReportView.viewEdit.failed.title"),
                        message: t("EditReportView.viewEdit.failed.message"),
                    });
                }
            });
    };
    const handleCancel = () => {
        hideFormModal();
    };

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    return (
        <>
            <SubpageLayout
                visible={props.visible}
                title={viewTitle}
                buttons={
                    <Button
                        variant="PRIMARY"
                        type={"submit"}
                        form={formIdForSubmit}
                        className={classNames(buttons.primaryButton, buttons.medium, form.submitButton)}
                        testId={testIds.workArea.report.manageReportViewDialog.submitButton}
                        disabled={(!create && disableSubmit) || allFiltersValid}
                    >
                        {create ? t("Common.create") : t("Common.save")}
                    </Button>
                }
            >
                <ManageReportViewForm
                    handleSubmit={create ? createView : editView}
                    handleCancel={handleCancel}
                    shared={props.viewDetails.shared}
                    name={props.name || props.viewDetails.name}
                    paths={props.paths}
                    create={create}
                    edit={!create}
                    columns={props.viewDetails.columns.length ? props.viewDetails.columns : defaultColumns}
                    own={props.viewDetails.own}
                    filters={props.viewDetails.filters}
                    formId={formIdForSubmit}
                    disableSubmit={setDisableSubmit}
                    validFilterCheck={setAllFiltersValid}
                />
            </SubpageLayout>
            <Modal
                isOpen={resultModalVisible}
                modalTitle={loading ? viewTitle : result.title}
                hideModal={hideResultModal}
            >
                {loading ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        <TextBlock>{result.message}</TextBlock>
                        <ButtonContainer noSpacingTop>
                            <Button onClick={hideResultModal} variant={"PRIMARY"}>
                                {t("Common.ok")}
                            </Button>
                        </ButtonContainer>
                    </>
                )}
            </Modal>
            <div>
                {allFiltersValid && (
                    <div className={style.errorBanner}>
                        <div className={style.warningIcon} tabIndex={0}>
                            <FailedRedNotificationIcon
                                backgroundColor={theme.errorIconColor}
                                iconColor={theme.contentBackgroundColor}
                            />
                        </div>
                        <p>{t("CreateReportView.form.errorBanner")}</p>
                    </div>
                )}
            </div>
        </>
    );
}
