import classNames from "classnames";
import { FormikErrors } from "formik";
import React, { useMemo } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import ExportIcon from "components/icons/ExportIcon";
import Warning from "components/icons/Warning";
import {
    BUNDLES_ADD_ONS,
    ChannelBundle,
    CorporateBundle,
    MANAGEMENT_CONSOLE_LICENSE,
    ProcessorBundle,
    SMB_BUNDLE,
} from "components/licenses/bundles";
import {
    BLANCCO_TOKEN_ID,
    FEATURE_TENANT_MANAGEMENT,
    isAmountAvailable,
    isExpirationDateValid,
    showOnlyTokenLicense,
} from "components/licenses/common";
import { createLicense } from "components/licenses/delivery-history/AddLicenseDeliveryView";
import DeliveryFormContent, {
    createDefaultSelectedLicense,
    DeliveryForm,
    License,
} from "components/licenses/delivery-history/DeliveryFormContent";
import LicenseSelectionForm from "components/licenses/delivery-history/LicenseSelectionForm";
import style from "components/licenses/subscription-expiry-warning-banner/subscription-expiry-warning-banner.scss";
import StaticTable from "components/support/api-guide/StaticTable";
import creationFormStyles from "components/tenants/add-tenant/add-tenant.scss";
import {
    FormValues,
    generateSelectableLicenses,
    isProductAddOn,
    isTokenLicense,
} from "components/tenants/add-tenant/AddTenantForm";
import editTenantStyle from "components/tenants/edit-tenant/edit-tenant.scss";
import { TenantsLicenses } from "components/tenants/edit-tenant/EditTenantView";
import { ComputedLicenses, LicenseData, MAX_DATE } from "domain/licenses";
import { CombinedTier, LicensingModel } from "domain/tenants";
import { TIER_TO_LICENSES_MAP } from "domain/tierRelatedMaps";
import { extractLatestLicenseRateUuid, ProductToRateList } from "services/licenses/LicenseService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import { formatDateWithoutTime, isExpired } from "utils/format";

import testIds from "testIds.json";

interface Props {
    defaultTenantLicenses: LicenseData[] | undefined;
    allTenantLicenses: TenantsLicenses[];
    tier: CombinedTier;
    licensingModel: LicensingModel;
    selectedLicenses: License[];
    setSelectedLicenses: (licenses: License[]) => void;
    deliveryDetails: DeliveryForm;
    errors: FormikErrors<DeliveryForm> | FormikErrors<FormValues>;
    handleChange: (
        e:
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
    rateVersions?: ProductToRateList[];
    inEdit: boolean;
    tenantUuid?: string;
    handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    loadingLicenses?: boolean;
    equivalentCommonLicenses?: number;
    addOns?: ComputedLicenses[];
    loading?: boolean;
    toUpdatePools?: boolean;
}

function generateDropdownLicenses(
    selectedTier: CombinedTier,
    dropdownLicenses: { productName: string; productId: string }[],
    licensingModel: LicensingModel
) {
    generateSelectableLicenses(dropdownLicenses, selectedTier, licensingModel);
}

function manageLicenseList(
    eachInAllLicenses: { productId: string; productName: string },
    predefinedLicenses: License[],
    selectedTier: string,
    tenantLicenses: LicenseData[] | undefined,
    computedLicensesToAdd?: number
) {
    const splitSmbType = selectedTier.split("_");
    const availableValue = splitSmbType[splitSmbType.length - 1];
    const parentLicense = tenantLicenses?.find(
        (eachParentLicense) => eachParentLicense.type == eachInAllLicenses.productId
    );

    if (parentLicense) {
        predefinedLicenses.push(
            createLicense(
                parentLicense.type || eachInAllLicenses.productId,
                0,
                eachInAllLicenses.productName,
                parentLicense.expirationDate,
                predefinedLicenses.length,
                parentLicense.available,
                SMB_BUNDLE.includes(selectedTier) ? Number(availableValue) : computedLicensesToAdd ?? 1,
                parentLicense.expirationDate
            )
        );
    }
}

export function populateWithLicenses(
    selectedTier: CombinedTier,
    licensingModel: LicensingModel,
    tenantLicenses: LicenseData[] | undefined,
    t: TFunction,
    prepopulatedLicenses?: ComputedLicenses[],
    commonLicense?: number
) {
    if (licensingModel === LicensingModel.OLD_MODEL) {
        return [createDefaultSelectedLicense(t)];
    }

    const dropdownLicenses: { productName: string; productId: string }[] = [];
    generateDropdownLicenses(selectedTier, dropdownLicenses, licensingModel);
    const predefinedLicenses: License[] = [];

    const isTopBundle =
        selectedTier == CorporateBundle.ENTERPRISE ||
        selectedTier == ProcessorBundle.PRO_SCALE ||
        selectedTier == ChannelBundle.PRO_SCALE;

    dropdownLicenses.forEach((license) => {
        const { productId } = license;

        if (prepopulatedLicenses) {
            const matchingLicense = prepopulatedLicenses.find((l) => l.licenseId === productId);
            if (matchingLicense) {
                manageLicenseList(license, predefinedLicenses, selectedTier, tenantLicenses, matchingLicense.amount);
                return;
            }
        }

        if (productId === MANAGEMENT_CONSOLE_LICENSE && isTopBundle) {
            manageLicenseList(license, predefinedLicenses, "", tenantLicenses);
            return;
        }

        if (!BUNDLES_ADD_ONS.includes(productId)) {
            manageLicenseList(license, predefinedLicenses, selectedTier, tenantLicenses, commonLicense ?? 1);
            return;
        }
    });

    return predefinedLicenses;
}

export const DeliveryTab = (props: Props) => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const dropdownLicenses: { productName: string; productId: string }[] = [];
    const filteredTenantLicenses: LicenseData[] = [];

    enum LicenseType {
        //Filters
        LICENSE_FILTER_ALL = "All licenses",
        LICENSE_FILTER_COMMON = "Common licenses",
        LICENSE_FILTER_ADDON = "Add-ons",
        LICENSE_FILTER_DISCARDED = "Discarded licenses",
        //License type used in table
        LICENSE_TYPE_COMMON = "Common license",
        LICENSE_TYPE_ADD_ON = "Add-on",
        LICENSE_TYPE_DISCARDED = "Discarded",
        LICENSE_TYPE_BUNDLE_PART = "Part of the bundle",
    }

    function generateLicenseFilterKeys(t: TFunction) {
        return new Map<CombinedTier, string>([
            [LicenseType.LICENSE_TYPE_COMMON, t("EditCustomerView.licenseType.commonLicense")],
            [LicenseType.LICENSE_TYPE_ADD_ON, t("EditCustomerView.licenseType.addOn")],
            [LicenseType.LICENSE_TYPE_DISCARDED, t("EditCustomerView.licenseType.discardedLicense")],
            [LicenseType.LICENSE_TYPE_BUNDLE_PART, t("EditCustomerView.licenseType.partOfBundleLicense")],
            [LicenseType.LICENSE_FILTER_ALL, t("EditCustomerView.licenseFilterByCategory.allLicenses")],
            [LicenseType.LICENSE_FILTER_COMMON, t("EditCustomerView.licenseFilterByCategory.commonLicenses")],
            [LicenseType.LICENSE_FILTER_ADDON, t("EditCustomerView.licenseFilterByCategory.addOnLicenses")],
            [LicenseType.LICENSE_FILTER_DISCARDED, t("EditCustomerView.licenseFilterByCategory.discardedLicenses")],
        ]);
    }
    const [selectedLicensesFilter, setSelectedLicensesFilter] = React.useState<string>(
        generateLicenseFilterKeys(t).get(LicenseType.LICENSE_FILTER_ALL) as string
    );

    const tierLicenses = TIER_TO_LICENSES_MAP.get(props.tier);
    const isCommonLicense = (license: TenantsLicenses) =>
        tierLicenses?.includes(license.productId) && !BUNDLES_ADD_ONS.includes(license.productId);
    const isAddOnLicense = (license: TenantsLicenses) => {
        return tierLicenses?.includes(license.productId) && BUNDLES_ADD_ONS.includes(license.productId);
    };
    const isDiscardedLicense = (license: TenantsLicenses) =>
        isExpired(license.expirationDate) || !tierLicenses?.includes(license.productId);
    const isTierPowerOrPro = props.tier === CorporateBundle.POWER || props.tier === ProcessorBundle.PRO;
    const isTierEnterpriseOrProScale =
        props.tier === CorporateBundle.ENTERPRISE || props.tier === ProcessorBundle.PRO_SCALE;
    const isTenantFeatureCommonLicense = (license: TenantsLicenses) =>
        license.productId == FEATURE_TENANT_MANAGEMENT &&
        isTierEnterpriseOrProScale &&
        tierLicenses?.includes(license.productId);
    const isFeatureLicense = (license: TenantsLicenses) => license.productId.startsWith("feature-");
    if (props.defaultTenantLicenses !== undefined && props.defaultTenantLicenses !== null) {
        if (showOnlyTokenLicense(props.licensingModel)) {
            filteredTenantLicenses.push(
                ...props.defaultTenantLicenses?.filter((each) => isTokenLicense(each.type) || isProductAddOn(each.type))
            );
        } else {
            filteredTenantLicenses.push(...props.defaultTenantLicenses?.filter((each) => !isTokenLicense(each.type)));
        }
    }
    const fetchLicences = () => {
        generateDropdownLicenses(props.tier, dropdownLicenses, props.licensingModel);
        const allLicenses: License[] = [];
        filteredTenantLicenses.forEach((tenantLicense) => {
            if (isExpirationDateValid(tenantLicense.expirationDate, MAX_DATE) && tenantLicense.available > 0) {
                const productDetails = dropdownLicenses.find((each) => each.productId === tenantLicense.type);
                if (typeof productDetails === "undefined") {
                    return;
                }
                allLicenses.push(
                    createLicense(
                        tenantLicense.type,
                        0,
                        productDetails.productName,
                        tenantLicense.expirationDate,
                        allLicenses.length,
                        tenantLicense.available,
                        0,
                        tenantLicense.expirationDate
                    )
                );
            }
        });
        return allLicenses;
    };

    function computeProductRate(productId: string) {
        const rateUUid = extractLatestLicenseRateUuid(props.rateVersions);
        const latestRate = props.rateVersions?.find((rate) => rate.uuid === rateUUid);

        if (latestRate) {
            return latestRate.productToRate.find((rate) => rate.productId === productId)?.rate;
        }
        return undefined;
    }

    const isTenantFeatureAddOn = (license: TenantsLicenses) =>
        license.productId == FEATURE_TENANT_MANAGEMENT && isTierPowerOrPro && isAddOnLicense;

    function highlightLicenseType(licenseType: string) {
        return (
            <>
                {(() => {
                    switch (licenseType) {
                        case generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_DISCARDED):
                            return (
                                <div
                                    style={{ color: theme.errorBackgroundColor }}
                                    className={classNames(editTenantStyle.highlightDiscardedLicenseType)}
                                >
                                    <strong>
                                        {generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_DISCARDED)}
                                    </strong>
                                </div>
                            );
                        case generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_ADD_ON):
                            return (
                                <div
                                    style={{ color: theme.infoBadgeTextColor }}
                                    className={classNames(editTenantStyle.highlightAddOnLicenseType)}
                                >
                                    <strong>{generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_ADD_ON)}</strong>
                                </div>
                            );
                        case generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_COMMON):
                            return (
                                <div
                                    style={{ color: theme.defaultStarColor }}
                                    className={classNames(editTenantStyle.highlightCommonLicenseType)}
                                >
                                    <strong>{generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_COMMON)}</strong>
                                </div>
                            );
                        case generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_BUNDLE_PART):
                            return (
                                <div
                                    style={{ color: theme.defaultStarColor }}
                                    className={classNames(editTenantStyle.highlightCommonLicenseType)}
                                >
                                    <strong>
                                        {generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_BUNDLE_PART)}
                                    </strong>
                                </div>
                            );
                        default:
                            return "";
                    }
                })()}
            </>
        );
    }

    const renderLicenseType = (license: TenantsLicenses) => {
        if (selectedLicensesFilter === generateLicenseFilterKeys(t).get(LicenseType.LICENSE_FILTER_ALL)) {
            if (isDiscardedLicense(license)) {
                return highlightLicenseType(
                    generateLicenseFilterKeys(t).get(LicenseType.LICENSE_FILTER_DISCARDED) as string
                );
            } else if (
                isTenantFeatureCommonLicense(license) ||
                (isCommonLicense(license) && !isTenantFeatureAddOn(license))
            ) {
                if (isFeatureLicense(license)) {
                    return highlightLicenseType(
                        generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_BUNDLE_PART) as string
                    );
                }
                return highlightLicenseType(
                    generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_COMMON) as string
                );
            } else if (
                isAddOnLicense(license) ||
                (license.productId === FEATURE_TENANT_MANAGEMENT && isTenantFeatureAddOn(license))
            ) {
                return highlightLicenseType(
                    generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_ADD_ON) as string
                );
            }
        }
        return null;
    };

    const getLicenseType = (license: TenantsLicenses) => {
        if (isDiscardedLicense(license)) {
            return generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_DISCARDED);
        }
        if (isTenantFeatureCommonLicense(license) || (isCommonLicense(license) && !isTenantFeatureAddOn(license))) {
            if (isFeatureLicense(license)) {
                return generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_BUNDLE_PART);
            }
            return generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_COMMON);
        }

        if (
            isAddOnLicense(license) ||
            (license.productId === FEATURE_TENANT_MANAGEMENT && isTenantFeatureAddOn(license))
        ) {
            return generateLicenseFilterKeys(t).get(LicenseType.LICENSE_TYPE_ADD_ON);
        }
    };

    function calculateCommonLicenseEquivalent(license: TenantsLicenses) {
        return (() => {
            const productRate = computeProductRate(license.productId);
            return productRate === undefined || productRate === null
                ? license.available
                : license.available * productRate;
        })();
    }

    const showCommonLicenseUsage = (license: TenantsLicenses) => {
        if (
            isDiscardedLicense(license) ||
            isFeatureLicense(license) ||
            isTenantFeatureCommonLicense(license) ||
            isAddOnLicense(license)
        ) {
            return t("Common.na");
        }

        if (isTenantFeatureAddOn(license)) {
            return calculateCommonLicenseEquivalent(license);
        }

        return calculateCommonLicenseEquivalent(license);
    };
    function loadTenantsLicensesOnFilter(license: TenantsLicenses) {
        return selectedLicensesFilter === generateLicenseFilterKeys(t).get(LicenseType.LICENSE_FILTER_ALL)
            ? [
                  <div
                      key={"license" + license.product}
                      data-testid={testIds.workArea.license.deliveryHistory.editDeliveryDialog.table.licenseLabel}
                  >
                      {license.product}
                  </div>,
                  <div
                      key={"licenseType" + license.product}
                      className={editTenantStyle.licenseTypeLabel}
                      data-testid={testIds.workArea.license.deliveryHistory.editDeliveryDialog.table.licenseTypeLabel}
                  >
                      {renderLicenseType(license)}
                  </div>,
                  <div
                      key={"remainingLicenses" + license.remainingLicenses}
                      className={classNames(editTenantStyle.staticDatalabel)}
                      data-testid={
                          testIds.workArea.license.deliveryHistory.editDeliveryDialog.table.remainingLicensesLabel
                      }
                  >
                      {license.remainingLicenses}
                  </div>,
                  <div
                      key={"commonLicensesConsumption" + license.available}
                      className={classNames(editTenantStyle.staticDatalabel)}
                      data-testid={
                          testIds.workArea.license.deliveryHistory.editDeliveryDialog.table
                              .commonLicensesConsumptionLabel
                      }
                  >
                      {showCommonLicenseUsage(license)}
                  </div>,
                  <div
                      key={"expirationDate" + license.expirationDate}
                      className={editTenantStyle.staticDatalabel}
                      data-testid={
                          testIds.workArea.license.deliveryHistory.editDeliveryDialog.table.expirationDateLabel
                      }
                  >
                      {formatDateWithoutTime(license.expirationDate)}
                  </div>,
                  <div
                      key={"licensePool" + license.licensePool}
                      className={classNames(editTenantStyle.staticDatalabel)}
                  >
                      {license.licensePool}
                  </div>,
              ]
            : [
                  <div
                      key={"license" + license.product}
                      data-testid={
                          testIds.workArea.license.deliveryHistory.createDeliveryDialog.licenseTypeSelect.itself
                      }
                  >
                      {license.product}
                  </div>,

                  <div
                      key={"remainingLicenses" + license.remainingLicenses}
                      className={classNames(editTenantStyle.staticDatalabel)}
                      data-testid={testIds.workArea.license.deliveryHistory.createDeliveryDialog.availableLabel}
                  >
                      {license.remainingLicenses}
                  </div>,
                  <div
                      key={"commonLicensesConsumption" + license.available}
                      className={classNames(editTenantStyle.staticDatalabel)}
                      data-testid={testIds.workArea.license.deliveryHistory.createDeliveryDialog.totalOfLicensesLabel}
                  >
                      {showCommonLicenseUsage(license)}
                  </div>,
                  <div
                      key={"expirationDate" + license.expirationDate}
                      className={editTenantStyle.staticDatalabel}
                      data-testid={
                          testIds.workArea.license.deliveryHistory.createDeliveryDialog.expirationDateInput.itself
                      }
                  >
                      {formatDateWithoutTime(license.expirationDate)}
                  </div>,
                  <div
                      key={"licensePool" + license.licensePool}
                      className={classNames(editTenantStyle.staticDatalabel)}
                  >
                      {license.licensePool}
                  </div>,
              ];
    }

    const allLicensesFilterHeaders = [
        {
            value: t("EditCustomerView.oldLicensesToBeDeleted.license"),
        },
        { value: t("EditCustomerView.oldLicensesToBeDeleted.licenseType"), className: editTenantStyle.tooltipOpen },
        {
            value: t("EditCustomerView.oldLicensesToBeDeleted.remainingLicenses"),
        },
        {
            value: t("EditCustomerView.oldLicensesToBeDeleted.commonLicensesUsage"),
        },
        {
            value: t("EditCustomerView.oldLicensesToBeDeleted.expirationDate"),
        },
        {
            value: t("EditCustomerView.oldLicensesToBeDeleted.licensePool"),
        },
    ];

    const otherFilterHeaders = [
        {
            value: t("EditCustomerView.oldLicensesToBeDeleted.license"),
        },
        {
            value: t("EditCustomerView.oldLicensesToBeDeleted.remainingLicenses"),
        },
        {
            value: t("EditCustomerView.oldLicensesToBeDeleted.commonLicensesUsage"),
        },
        {
            value: t("EditCustomerView.oldLicensesToBeDeleted.expirationDate"),
        },
        {
            value: t("EditCustomerView.oldLicensesToBeDeleted.licensePool"),
        },
    ];

    const columns = [
        ...(selectedLicensesFilter === generateLicenseFilterKeys(t).get(LicenseType.LICENSE_FILTER_ALL)
            ? allLicensesFilterHeaders
            : otherFilterHeaders),
    ];

    const allAvailableLicenses = fetchLicences();
    type FilterFunction = (license: TenantsLicenses) => boolean;
    type FilterMap = {
        [LicenseType.LICENSE_FILTER_ADDON]: FilterFunction;
        [LicenseType.LICENSE_FILTER_COMMON]: FilterFunction;
        [LicenseType.LICENSE_TYPE_DISCARDED]: FilterFunction;
        [LicenseType.LICENSE_FILTER_ALL]: FilterFunction;
    };

    function showTenantsLicenses() {
        const filteredLicenses = filterAndMapLicenses(props.allTenantLicenses, selectedLicensesFilter);
        return (
            <div className={classNames(editTenantStyle.staticTableTopMarginGap)}>
                <StaticTable
                    headers={columns}
                    cells={
                        filteredLicenses && filteredLicenses.length > 0
                            ? filteredLicenses
                            : [[t("EditCustomerView.oldLicensesToBeDeleted.nothingToDiscard")]]
                    }
                    loading={props.loadingLicenses}
                    emptyStateMessage={t("EditCustomerView.oldLicensesToBeDeleted.nothingToDiscard")}
                />
            </div>
        );
    }
    function filterAndMapLicenses(
        licenses: TenantsLicenses[],
        filter: string
    ): (string | JSX.Element | null)[][] | undefined {
        const filterMap: FilterMap = {
            [LicenseType.LICENSE_TYPE_DISCARDED]: (license: TenantsLicenses): boolean => {
                return Boolean(isDiscardedLicense(license));
            },

            [LicenseType.LICENSE_FILTER_COMMON]: function (license: TenantsLicenses): boolean {
                return Boolean(
                    !isDiscardedLicense(license) &&
                        ((isCommonLicense(license) && !isTenantFeatureAddOn(license)) ||
                            (isAddOnLicense(license) && isTenantFeatureCommonLicense(license)))
                );
            },
            [LicenseType.LICENSE_FILTER_ADDON]: function (license: TenantsLicenses): boolean {
                return Boolean(
                    isAddOnLicense(license) &&
                        !isDiscardedLicense(license) &&
                        (isTenantFeatureAddOn(license) || !isTenantFeatureCommonLicense(license))
                );
            },
            [LicenseType.LICENSE_FILTER_ALL]: (): boolean => true,
        };
        const filterKey =
            (generateLicenseFilterKeys(t).get(filter) as keyof FilterMap) || LicenseType.LICENSE_FILTER_ALL;
        const filterFunction = filterMap[filterKey] || (() => false);
        return licenses.filter(filterFunction).map(loadTenantsLicensesOnFilter);
    }

    const csvFileName = props.tenantUuid + "_" + "licensing_change.csv";
    const exportDeletedLicenses = (tenantsLicenses: TenantsLicenses[]) => {
        const downloadCSV = () => {
            props.handleChange;
            const csvString = [
                [
                    t("EditCustomerView.oldLicensesToBeDeleted.license"),
                    t("EditCustomerView.oldLicensesToBeDeleted.licenseType"),
                    t("EditCustomerView.oldLicensesToBeDeleted.commonLicensesUsage"),
                    t("EditCustomerView.oldLicensesToBeDeleted.remainingLicenses"),
                    t("EditCustomerView.oldLicensesToBeDeleted.expirationDate"),
                    t("EditCustomerView.oldLicensesToBeDeleted.licensePool"),
                ],
                ...tenantsLicenses.map((item) => [
                    item.product,
                    getLicenseType(item),
                    calculateCommonLicenseEquivalent(item),
                    item.remainingLicenses,
                    item.expirationDate,
                    item.licensePool,
                ]),
            ]
                .map((row) => row.join(","))
                .join("\n");

            const blob = new Blob([csvString], { type: "text/csv" });

            const url = URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.download = csvFileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        };
        return (
            !props.loading && (
                <button
                    type="button"
                    onClick={downloadCSV}
                    className={classNames(buttons.textButton, editTenantStyle.exportButton)}
                >
                    <ExportIcon color={theme.linkTextColor} />
                    {t("LicenseEvent.export.title")}
                </button>
            )
        );
    };

    const filterOptions = useMemo(
        () => [
            generateLicenseFilterKeys(t).get(LicenseType.LICENSE_FILTER_ALL),
            generateLicenseFilterKeys(t).get(LicenseType.LICENSE_FILTER_COMMON),
            generateLicenseFilterKeys(t).get(LicenseType.LICENSE_FILTER_ADDON),
            generateLicenseFilterKeys(t).get(LicenseType.LICENSE_FILTER_DISCARDED),
        ],
        []
    );

    const handleFilterClick = (filter: string) => {
        setSelectedLicensesFilter(filter);
    };

    return (
        <div>
            <DeliveryFormContent
                availableLicenses={allAvailableLicenses}
                inTenantCreation={true}
                ownedLicenses={[]}
                selectedLicenses={props.selectedLicenses}
                setSelectedLicenses={(licenses) => {
                    props.setSelectedLicenses(licenses);
                }}
                deliveryForm={props.deliveryDetails}
                errors={props.errors}
                handleChange={props.handleChange}
                licensingModel={props.licensingModel}
                rateVersions={props.rateVersions}
                selectedTier={props.tier}
                handleBlur={props.handleBlur}
                toUpdatePools={props.toUpdatePools}
                equivalentCommonLicenses={props.equivalentCommonLicenses}
                addOnLicenses={props.addOns}
            />
            <div
                className={classNames(creationFormStyles.deliveryTable, {
                    [editTenantStyle.deliverDetailsTableContainer]: props.inEdit,
                })}
            >
                <LicenseSelectionForm
                    newDeal={true}
                    availableLicenses={allAvailableLicenses.sort((currentLicense, nextLicense) =>
                        currentLicense.productName.localeCompare(nextLicense.productName)
                    )}
                    ownedLicenses={[]}
                    deliveryForm={props.deliveryDetails}
                    selectedLicenses={props.selectedLicenses}
                    setSelectedLicenses={(licenses) => {
                        props.setSelectedLicenses(licenses);
                    }}
                />
                {props.inEdit ? (
                    <div className={classNames(editTenantStyle.topMarginSmall)}>
                        <div className={classNames(style.warningBanner, style.subscriptionContainer)}>
                            <div className={style.textAndBannerContainer}>
                                <Warning color={theme.secondaryWarningBackgroundColor} width={15} height={15} />
                                <div className={style.textDisplay}>
                                    {t("EditCustomerView.oldLicensesToBeDeleted.note", {
                                        commonLicenseAmount: props.equivalentCommonLicenses ?? 0,
                                        addOnsCount: props.addOns ? props.addOns.length : 0,
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className={classNames(editTenantStyle.topMarginSmall)}>
                            {filterOptions.map((option) => (
                                <span
                                    key={option}
                                    style={{ color: theme.chartOutlineColor }}
                                    className={classNames(
                                        selectedLicensesFilter === option
                                            ? editTenantStyle.applyLicenseFilterSelected
                                            : editTenantStyle.applyLicenseFilterUnSelected
                                    )}
                                    onClick={() => {
                                        handleFilterClick(option as string);
                                    }}
                                >
                                    {option}
                                </span>
                            ))}
                            {exportDeletedLicenses(props.allTenantLicenses)}
                        </div>
                        {showTenantsLicenses()}
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export function isAnyLicenseInvalid(selectedLicenses: License[]): boolean {
    return selectedLicenses.some(
        (item) =>
            isNaN(item.licensesToAdd) ||
            item.totalOfLicenses < 0 ||
            !isExpirationDateValid(
                item.expirationDate,
                item.parentLicenseExpirationDate
                    ? new Date(item.parentLicenseExpirationDate)
                    : MAX_DATE.toLocaleDateString()
            ) ||
            !isAmountAvailable(item)
    );
}

export function disableBatchPoolUpdate(
    selectedLicenses: License[],
    commonLicensesEquivalent?: number,
    addOns?: ComputedLicenses[]
) {
    for (const eachLicense of selectedLicenses) {
        if (eachLicense.licenseType === BLANCCO_TOKEN_ID) {
            if (commonLicensesEquivalent && eachLicense.licensesToAdd < commonLicensesEquivalent) {
                return true;
            }
        } else {
            const foundEquivalent = addOns?.find((eachAddOn) => eachAddOn.licenseId === eachLicense.licenseType);
            if (foundEquivalent && eachLicense.licensesToAdd < foundEquivalent.amount) {
                return true;
            }
        }
    }
    return false;
}
