import classNames from "classnames";
import * as React from "react";
import { TFunction, useTranslation } from "react-i18next";

import Modal from "components/modal/Modal";
import NewModelPricingTierView from "components/tenants/NewModelPricingTierView";
import OldModelPricingTierView from "components/tenants/OldModelPricingTierView";
import style from "components/tenants/pricing-tier-view.scss";
import { CombinedTier, LicensingModel, TenantType } from "domain/tenants";
import { generateTierToTranslationMap } from "domain/tierRelatedMaps";
import buttons from "styles/buttons.scss";

import testIds from "testIds.json";

interface Props {
    tenantType: TenantType;
    visibility: boolean;
    setVisibility: (visibility: boolean) => void;
    selectedTier: CombinedTier;
    tenantModal: LicensingModel;
}

export const CHECKMARK_SIZE = 18;
export const STROKE_WIDTH = 1.5;

export function updateSelectedTierTitle(selected: boolean, title: string, selectedTier: string, t: TFunction) {
    return selected
        ? t("AddCustomerForm.pricingTierDialog.header.currentPlan", {
              tierPlan: generateTierToTranslationMap(t).get(selectedTier),
          })
        : title;
}

const PricingTierView = (props: Props): JSX.Element => {
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={props.visibility}
            hideModal={() => {
                props.setVisibility(false);
            }}
            modalTitle={
                props.tenantModal === LicensingModel.OLD_MODEL
                    ? t("AddCustomerForm.pricingTierDialog.title")
                    : t("AddCustomerForm.pricingTierDialog.titleBundle")
            }
        >
            <div className={style.model}>
                {props.tenantModal == LicensingModel.OLD_MODEL ? (
                    <OldModelPricingTierView selectedTier={props.selectedTier} tenantType={props.tenantType} />
                ) : (
                    <NewModelPricingTierView
                        tenantType={props.tenantType}
                        tenantCreation={false}
                        selectedTier={props.selectedTier}
                    />
                )}

                <div className={style.okButtonContainer}>
                    <button
                        className={classNames(buttons.primaryButton, buttons.medium, buttons.okButton)}
                        data-testid={testIds.common.dialog.closeButton}
                        onClick={() => props.setVisibility(false)}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default PricingTierView;
